import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { ThemeContext } from 'utils/constants';
import { ArticleTopper, ArticleIndex, SEO, CTA } from 'components';

const ArticleLanding = ({ data, location: { pathname }, pageContext }) => {
  const {
    contentfulInsightsLanding: {
      introHeadline,
      introText,
      featuredInsight,
      featuredTopics,
      cta,
      hideFooterCta,
      seo,
    },
    allContentfulArticle: { edges: articleEdges },
    allTopics: { edges: topicEdges },
  } = data;
  const context = useContext(ThemeContext);
  const { numPages, currentPage, basePath } = pageContext;

  const topics = topicEdges.map(({ node }) => node);
  const articles = articleEdges.map(({ node }) => node);

  const articlesWithoutFeaturedInsight = articles.filter(
    ({ title }) => title !== featuredInsight.title,
  );

  return (
    <div className={context}>
      <Helmet
        bodyAttributes={{
          class: 'has-animation',
        }}
      />
      <SEO {...seo} pathname={pathname} />
      <ArticleTopper article={featuredInsight} featured={true} />
      <ArticleIndex
        articles={articlesWithoutFeaturedInsight}
        basePath={basePath}
        className="on-article-landing"
        currentPage={currentPage}
        numPages={numPages}
        introHeadline={introHeadline}
        introText={introText}
        topics={topics}
        featuredTopics={featuredTopics}
      />
      {!hideFooterCta && <CTA {...cta} onFooter />}
    </div>
  );
};

ArticleLanding.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    numPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    basePath: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query insightsLandingPage($skip: Int!, $limit: Int!) {
    contentfulInsightsLanding {
      introHeadline
      introText {
        introText
      }
      featuredInsight {
        title
        intro {
          intro
        }
        slug
        date(formatString: "MM.DD.YY")
        author {
          name
          image {
            title
            description
            file {
              url
            }
          }
        }
        content {
          json
        }
        articleImage {
          description
          title
          file {
            url
          }
        }
        topics {
          name
          slug
          image {
            title
            description
            file {
              url
            }
          }
        }
      }
      featuredTopics {
        name
        slug
        image {
          title
          file {
            url
          }
        }
      }
      cta {
        ...CTA
      }
      hideFooterCta
      seo {
        ...SEO
      }
    }

    allTopics: allContentfulTopic(sort: { fields: name }) {
      edges {
        node {
          name
          slug
        }
      }
    }

    allContentfulArticle(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          intro {
            intro
          }
          slug
          date(formatString: "MM.DD.YY")
          author {
            name
            image {
              title
              description
              file {
                url
              }
            }
          }
          articleImage {
            description
            title
            file {
              url
            }
          }
          featuredImage {
            title
            description
            file {
              url
            }
          }
          thumbnailImage {
            title
            description
            file {
              url
            }
          }
          topics {
            name
            slug
            image {
              title
              description
              file {
                url
              }
            }
          }
          content {
            json
          }
        }
      }
    }
  }
`;

export default ArticleLanding;
